<template>
  <div>
    <heade></heade>
    <div class="com" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="com_box">
        <div class="con_top">
          <div class="con_top_left">
            <div :class="[
              videoCateWhere.id == 20
                ? 'com-left-top micro_course_title_height'
                : 'com-left-top',
              videoCateWhere.id == 21
                ? 'com-left-top shehui_micro_course_title_height'
                : 'com-left-top',
            ]">
              {{ video.name }}
            </div>
            <div :class="[
              videoCateWhere.id == 20
                ? 'con_left_name micro_course_left_name'
                : 'con_left_name',
              videoCateWhere.id == 21
                ? 'con_left_name shehui_micro_course_left_name'
                : 'con_left_name',
            ]">
              <div class="nice-scroll">
                <div v-for="(item, index) in video.course_division_category" :key="index">
                  <div v-for="(items, indexs) in item.course_division" :key="indexs" @click="
                    replayDivisionCourse(
                      video.id,
                      items.division_content_id,
                      items.charge_mode_id,
                      items.course_vid
                    )
                    " :id="video.id" :division_id="items.division_content_id">
                    <p :class="isActive == items.division_content_id ||
                      (indexs == 0 && queryWhere.division_id == 0)
                      ? 'division-active'
                      : 'division'
                      ">
                      {{ items.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="con_right">
            <div class="prism-player-box">
              <vue-aliplayer-v2 v-if="options.vid != ''" ref="VueAliplayerV2" :options="options" />
            </div>
          </div>
        </div>
        <div :class="[
          videoCateWhere.id == 11 ||
            videoCateWhere.id == 13 ||
            videoCateWhere.id == 15
            ? 'link-box nice-scroll'
            : 'link-box',
        ]">
          <div :class="[
            videoCateWhere.id == 11 ? 'links_11' : '',
            videoCateWhere.id == 12 ? 'links_12' : '',
            videoCateWhere.id == 13 ? 'links_13' : '',
            videoCateWhere.id == 14 ? 'links_14' : '',
            videoCateWhere.id == 15 ? 'links_15' : '',
            videoCateWhere.id == 16 ? 'links_16' : '',
            videoCateWhere.id == 17 ? 'links_17' : '',
            videoCateWhere.id == 18 ? 'links_18' : '',
            videoCateWhere.id == 19 ? 'links_19' : '',
            videoCateWhere.id == 10 ? 'links_10' : '',
            videoCateWhere.id == 20 ? 'links_20' : '',
            videoCateWhere.id == 21 ? 'links_21' : '',
            videoCateWhere.id == 35 ? 'links_35' : '',
          ]">
            <router-link v-for="(item, index) in videos" :key="index" :to="{
              path: pathUrl,
              query: { id: item.id },
            }" :class="item.id == video.id ? 'items active' : 'items'">
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import $store from "../../store";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import VueAliplayerV2 from "vue-aliplayer-v2";
import { scroll } from "@utils/scroll";
import { getEncryptionVideo, getVideoList, getVideoPlayAuth } from "@api/public";
export default {
  components: {
    heade,
    foot,
    VueAliplayerV2,
  },
  props: {},
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
    videoCateWhere() { },
  },
  data() {
    return {
      videosByCate: [],
      video: {},
      queryWhere: {},
      videos: [],
      videoCateWhere: {},
      options: {
        autoplay: false,
        height: "100%",
        playsinline: true,
        vid: "",
        playauth: "",
        cover: "",
        encryptType: 1,
      },
      isActive: false,
      backgroud: "",
      pathUrl: ""
    };
  },
  mounted: function () {
    let that = this;
    that.getEncryptionVideo();
  },
  created: function () {
    this.queryWhere = this.$route.query;
    this.queryWhere.division_id = 0;
    this.backgroud = this.$route.meta.background;
    this.videoCateWhere.id = this.$route.meta.id;
    this.pathUrl = this.$route.meta.path;
    document.title = this.$route.meta.title;
  },
  methods: {
    getEncryptionVideo: function () {
      let that = this;
      getEncryptionVideo(that.queryWhere).then((res) => {
        that.$set(that, "video", res.data);
        that.$set(that.options, "vid", res.data.course_vid);
        that.$set(that.options, "playauth", res.data.play_auth);
        that.$set(that.videoCateWhere, "id", res.data.course_cate_id);
        if (res.data.copy != "") {
          that.$set(that.options, "cover", res.data.copy);
        }
        getVideoList({ course_cate_id: that.videoCateWhere.id, limit: 30 }).then(function (res) {
          that.$set(that, "videos", res.data.list);
          scroll($(".nice-scroll"));
        });
      });
      scroll($(".nice-scroll"));
    },
    replayDivisionCourse: function (id, division_id, charge_mode, courseVid) {
      if (
        charge_mode == 1 ||
        ($store.state.app.userInfo != null &&
          $store.state.app.userInfo.is_empower)
      ) {
        this.queryWhere.id = id;
        this.queryWhere.division_id = division_id;
        this.isActive = division_id;
        this.options.autoplay = true;
        getVideoPlayAuth({ course_vid: courseVid, region: 1 }).then((res) => {
          this.options.vid = courseVid;
          this.options.playauth = res.data.videoPlayAuth.playAuth;
          if (this.options.vid != "") {
            this.replayByVidAndPlayAuth(this.options.vid, this.options.playauth);
            this.play();
          }
        })
      } else {
        if (!$store.state.app.token) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content: "您还未登录！是否去登录？",
              btn: ["是", "否"],
              closeBtn: 0,
              skin: "layui-layer-molv",
              yes: function () {
                window.location.href = "/login";
              },
            });
          });
        } else if (!$store.state.app.userInfo.is_empower) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content:
                "该部分内容为课题实验校、示范区会员账号专属内容，如需加入课题实验，请点击下方“课题申报”链接，按照要求提交信息，经课题组审核批准后，方可成为课题实验校、示范区，获得专属课题账号，正常查看全部内容。",
              closeBtn: 0,
              btn: ["课题申报", "关闭"],
              success: function (layero) {
                var btn = layero.find(".layui-layer-btn");
                btn.find(".layui-layer-btn0").attr({
                  href: "/application/shenbaobiao",
                });
              },
            });
          });
        }
      }
    },
    replayByVidAndPlayAuth(vid, playauth) {
      this.$refs.VueAliplayerV2.replayByVidAndPlayAuth(vid, playauth);
    },
    play() {
      this.$refs.VueAliplayerV2.play();
    },
    pause() {
      this.$refs.VueAliplayerV2.pause();
    },

    replay() {
      this.$refs.VueAliplayerV2.replay();
    },
    handleCourseName: function (data) {
      if ((data.name).indexOf("《") == -1) {
        data.name = "《" + data.name;
      }
      if ((data.name).indexOf("上") == -1 && (data.name).indexOf("下") == -1 && (data.name).indexOf("》") == -1) {
        data.name = item.name + "》";
      }
      if ((data.name).indexOf("上") != -1) {
        data.name = (data.name).substring(0, (data.name).indexOf("上")) + "》（上）"
      }
      if ((data.name).indexOf("下") != -1) {
        data.name = (data.name).substring(0, (data.name).indexOf("下")) + "》（下）"
      }
    },
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.con_top {
  width: 1484px;
  height: 870px;
  margin: 0 auto;
  padding-top: 325px;
}

.con_top_left {
  position: relative;
  width: 240px;
  height: 700px;
  left: 60px;
  top: 80px;
  text-align: center;
  float: left;
}

.con_left_name {
  width: 100%;
  height: 480px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}

.con_left_name.micro_course_left_name {
  height: 300px !important;
}

.con_left_name.shehui_micro_course_left_name {
  height: 400px !important;
}

.con_left_name>div>div p {
  margin: 0;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
}

.con_left_name>div>div p.division-active {
  color: #c3a769 !important;
}

.con_left_name>div>div p.division {
  color: #ffffff !important;
  width: 100%;
}

.con_left_name>.nice-scroll>div>div+div {
  margin-top: 25px;
}

.con_left_name>.nice-scroll>div>div:hover {
  cursor: pointer;
}

.con_left_name::-webkit-scrollbar {
  display: none;
}

.links_10 {
  width: 564px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.links_11 {
  position: relative;
  width: 2390px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links_12 {
  position: relative;
  width: 620px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 278px;
}

.links_13 {
  position: relative;
  width: 1310px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links_14 {
  position: relative;
  width: 1060px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 56px;
}

.links_15 {
  position: relative;
  width: 1460px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links_15 .items {
  line-height: 28px;
}

.links_16 {
  position: relative;
  width: 980px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 84px;
}

.links_17 {
  position: relative;
  width: 870px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 140px;
}

.links_18 {
  position: relative;
  width: 560px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 306px;
}

.links_19 {
  position: relative;
  width: 948px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 114px;
}

.links_20 {
  position: relative;
  width: 1094px;
  height: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 56px;
}

.links_21 {
  position: relative;
  width: 980px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 114px;
}

.links_35 {
  position: relative;
  width: 535px;
  height: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 90px;
  left: 390px;
  margin-top: -20px;
}

.link {
  width: 1126px;
  height: 200px;
  padding-left: 100px;
  display: flex;
  align-items: center;
}

.link-box {
  position: relative;
  width: 1180px;
  height: 220px !important;
  overflow-y: hidden !important;
  margin-top: 75px;
  left: 70px;
}

.links_10 .items {
  width: 52px;
  padding-left: 5px;
}

.link .items {
  font-size: 18px;
  line-height: 29px;
  margin-right: 28px;
}

.items {
  font-weight: 600;
  font-family: "楷体";
  writing-mode: vertical-lr;
  text-align: center;
  color: #006649;
  font-size: 20px;
}

.items:hover {
  color: #e2cea0;
}

.link-box .active {
  color: #900c0c;
}

.con_right {
  position: relative;
  float: left;
  width: 974px;
  height: 73%;
  display: flex;
  flex-direction: column;
  right: 0;
  left: 150px;
  top: 110px;
}

.prism-player-box {
  position: relative;
  height: 100% !important;
  width: 100%;
  z-index: 1000;
  background-color: #000;
}

.prism-player-box .prism-player {
  height: 100% !important;
}

.nice-scroll {
  overflow-y: auto;
  height: 100%;
}

.com-left-top.micro_course_title_height {
  height: 340px !important;
  word-break: keep-all;
}

.com-left-top.shehui_micro_course_title_height {
  height: 230px !important;
  word-break: keep-all;
}
</style>
