var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heade'),_c('div',{staticClass:"com",style:({
    backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
  })},[_c('div',{staticClass:"com_box"},[_c('div',{staticClass:"con_top"},[_c('div',{staticClass:"con_top_left"},[_c('div',{class:[
            _vm.videoCateWhere.id == 20
              ? 'com-left-top micro_course_title_height'
              : 'com-left-top',
            _vm.videoCateWhere.id == 21
              ? 'com-left-top shehui_micro_course_title_height'
              : 'com-left-top',
          ]},[_vm._v(" "+_vm._s(_vm.video.name)+" ")]),_c('div',{class:[
            _vm.videoCateWhere.id == 20
              ? 'con_left_name micro_course_left_name'
              : 'con_left_name',
            _vm.videoCateWhere.id == 21
              ? 'con_left_name shehui_micro_course_left_name'
              : 'con_left_name',
          ]},[_c('div',{staticClass:"nice-scroll"},_vm._l((_vm.video.course_division_category),function(item,index){return _c('div',{key:index},_vm._l((item.course_division),function(items,indexs){return _c('div',{key:indexs,attrs:{"id":_vm.video.id,"division_id":items.division_content_id},on:{"click":function($event){return _vm.replayDivisionCourse(
                    _vm.video.id,
                    items.division_content_id,
                    items.charge_mode_id,
                    items.course_vid
                  )}}},[_c('p',{class:_vm.isActive == items.division_content_id ||
                    (indexs == 0 && _vm.queryWhere.division_id == 0)
                    ? 'division-active'
                    : 'division'},[_vm._v(" "+_vm._s(items.name)+" ")])])}),0)}),0)])]),_c('div',{staticClass:"con_right"},[_c('div',{staticClass:"prism-player-box"},[(_vm.options.vid != '')?_c('vue-aliplayer-v2',{ref:"VueAliplayerV2",attrs:{"options":_vm.options}}):_vm._e()],1)])]),_c('div',{class:[
        _vm.videoCateWhere.id == 11 ||
          _vm.videoCateWhere.id == 13 ||
          _vm.videoCateWhere.id == 15
          ? 'link-box nice-scroll'
          : 'link-box',
      ]},[_c('div',{class:[
          _vm.videoCateWhere.id == 11 ? 'links_11' : '',
          _vm.videoCateWhere.id == 12 ? 'links_12' : '',
          _vm.videoCateWhere.id == 13 ? 'links_13' : '',
          _vm.videoCateWhere.id == 14 ? 'links_14' : '',
          _vm.videoCateWhere.id == 15 ? 'links_15' : '',
          _vm.videoCateWhere.id == 16 ? 'links_16' : '',
          _vm.videoCateWhere.id == 17 ? 'links_17' : '',
          _vm.videoCateWhere.id == 18 ? 'links_18' : '',
          _vm.videoCateWhere.id == 19 ? 'links_19' : '',
          _vm.videoCateWhere.id == 10 ? 'links_10' : '',
          _vm.videoCateWhere.id == 20 ? 'links_20' : '',
          _vm.videoCateWhere.id == 21 ? 'links_21' : '',
          _vm.videoCateWhere.id == 35 ? 'links_35' : '',
        ]},_vm._l((_vm.videos),function(item,index){return _c('router-link',{key:index,class:item.id == _vm.video.id ? 'items active' : 'items',attrs:{"to":{
            path: _vm.pathUrl,
            query: { id: item.id },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)])])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }